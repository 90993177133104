//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "fonts";

// If you do not want to load the customized bootstrap version, just use the following line.
// @import "~bootstrap/scss/bootstrap";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

body {
}

.wrapper {
    height: 100vh;
    display: flex;
    align-items: center;
}

h2 {
    margin: 0 0 3.0rem 0;
    font: {
        size: 2.25rem;
        weight: 700;
    }
}

figure {
    width: 100%;
    max-width: 480px;

    img {
        width: 100%;
        height: 100%;
        object: {
            fit: cover;
        }

        @include media-breakpoint-down(md) {
            margin-top: 2.0rem;
            padding: 0 10%;
        }
    }
}

//---------------------------------------------
// 3.Components
//---------------------------------------------

@import "components/slider";
